<template>
  <div class="text-center">
    <h1>Identity Management <br /> {{ $t('of.the.label') }} <br /><img alt="TU Nürnberg" src="../assets/icons/tun/logo.png"></h1>

    <b-card-group deck class="mx-0">
      <b-card
          border-variant="primary"
          :header="$t('registration.label')"
          header-bg-variant="primary"
          header-text-variant="white"
          align="center">
        <b-card-text>
          {{ $t('atm.not.available.label') }}
<!--          Um ein Benutzerprofil der Technischen Universität Nürnberg (TU Nürnberg) zu erhalten und zu verwalten, müssen Sie sich einmalig registrieren. Das bedeutet das Anlegen und Eintragen Ihrer persönlichen Daten in die Datenbank der TU Nürnberg. Es ist der erste Schritt, wenn Sie zum Beispiel an der TU Nürnberg eine Gastkennung nutzen wollen. Um einen Account vollständig nutzen zu können, ist eine Mitgliedschaft (Zugehörigkeit) notwendig. Diese erhalten Sie z. B. mit einem Vertragsverhältnis an der TU Nürnberg.-->
        </b-card-text>
      </b-card>
      <b-card
          border-variant="primary"
          header="Self Service"
          header-bg-variant="primary"
          header-text-variant="white"
          align="center">
        <b-card-text>
          {{ $t('self.service.info.text.label')}}
        </b-card-text>
      </b-card>
      <b-card
          border-variant="primary"
          header="Administration"
          header-bg-variant="primary"
          header-text-variant="white"
          align="center">
        <b-card-text>
          {{ $t('administration.info.text.label')}}
        </b-card-text>
      </b-card>
    </b-card-group>
    <div v-if="!currentUser" class="mt-5">
      <b-button @click="login" size="lg" variant="dark">
        <b-icon-box-arrow-in-right/> {{ $t('start.signin.label') }}
      </b-button>
      <div v-if="redirectAfterLogin" class="text-secondary small"><b-icon-x-circle @click="clearRedirect" class="cursorPointer" :title="$t('start.remove.redirect.title')"/> {{ redirectAfterLogin }}</div>
    </div>
  </div>
</template>

<script>
import { authMixin } from '@/mixins/auth.mixin'

export default {
  name: 'Start',
  mixins: [authMixin],
  computed: {
    redirectAfterLogin () {
      return this.$store.state.auth.redirectAfterLogin
    }
  },
  methods: {
    clearRedirect () {
      this.$store.commit('auth/clearRedirectAfterLogin')
    },
    login () {
      this.$store.commit('auth/triggerLogin', true)
    }
  }
}
</script>

<style>
</style>

import DefaultApiService from '@/services/defaultApi.service'

const defaultApiService = new DefaultApiService()
const ENDPOINT_MISSING = 'localeMessageMissing'

class LocaleMessageService {
  missing (data, params) {
    return defaultApiService.update(ENDPOINT_MISSING, data, { params: params, withCredentials: true }).then(
      response => {
        return response.data
      },
      error => defaultApiService.errorHandler(error)
    )
  }
}

export default new LocaleMessageService()

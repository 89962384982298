import SockJS from 'sockjs-client'
import Stomp from 'stompjs'

class StompService {
    STOMP_URL = process.env.VUE_APP_STOMP_URL;

    sockjs = null

    client = null

    debugEnabled = false

    /** config options */
    HEARTBEAT_OUTGOING = 2000
    HEARTBEAT_INCOMING = 3000
    RECONNECT_DELAY = 3500

    APP_ENDPOINT = '/app/'

    TOPIC_ENDPOINT = '/topic/'

    USER_TOPIC_NAME = 'bus'

    PORTAL_TOPIC_NAME = 'portal'

    ADMIN_TOPIC_NAME = 'admin'

    USER_TOPIC_PREFIX = '/user' + this.TOPIC_ENDPOINT

    /** topics for subscribing **/
    USER_DEF_TOPIC = this.USER_TOPIC_PREFIX + this.USER_TOPIC_NAME

    PORTAL_DEF_TOPIC = this.TOPIC_ENDPOINT + this.PORTAL_TOPIC_NAME

    ADMIN_DEF_TOPIC = this.TOPIC_ENDPOINT + this.ADMIN_TOPIC_NAME

    /** endpoints for sending **/
    USER_APP_ENDPOINT = this.APP_ENDPOINT + this.USER_TOPIC_NAME

    PORTAL_APP_ENDPOINT = this.APP_ENDPOINT + this.PORTAL_TOPIC_NAME

    ADMIN_APP_ENDPOINT = this.APP_ENDPOINT + this.ADMIN_TOPIC_NAME

    subscriptions = {}

    init () {
      this.sockjs = new SockJS(this.STOMP_URL)
      this.client = Stomp.over(this.sockjs)
      this.client.heartbeat.outgoing = this.HEARTBEAT_OUTGOING // client will send heartbeats every HEARTBEAT_OUTGOING ms
      this.client.heartbeat.incoming = this.HEARTBEAT_INCOMING // client wants to receive heartbeats every HEARTBEAT_INCOMING ms
      this.client.reconnect_delay = this.RECONNECT_DELAY

      this.client.debug = (msg) => this.debug(msg)
    }

    debug (...params) {
      if (this.debugEnabled) {
        console.debug(...params)
      }
    }

    disconnect (disconnectCallback = null) {
      if (!disconnectCallback) {
        disconnectCallback = () => {
          console.error('[StompService]', 'disconnected')
        }
      }

      for (const key in this.subscriptions) {
        this.debug('Unsubscribing web socket', key)
        this.unsubscribe(this.subscriptions[key])
      }

      this.client.disconnect(disconnectCallback)
      this.sockjs.close()
    }

    isConnected () {
      let connected = false
      if (this.client) {
        connected = this.client.connected
      }
      return connected
    }

    checkClient () {
      if (this.isConnected()) {
        this.debug('[StompService]', 'Web Socket is already connected')
      } else {
        this.debug('[StompService]', 'Connecting to web socket')
        this.init()
      }
    }

    getClient () {
      this.checkClient()
      return this.client
    }

    connect (subscribeCallback, errorCallback = null, headers = null) {
      if (!errorCallback) {
        errorCallback = (error) => {
          console.error('[StompService]', error)
        }
      }

      if (!headers) {
        headers = {}
      }

      this.checkClient()

      this.client.connect(headers, subscribeCallback, errorCallback)
    }

    subscribe (topic, subscribeCallback, headers = null) {
      this.checkClient()

      if (!headers) {
        headers = {}
      }

      this.debug('[StompService]', 'Subscribing to topic', topic)

      const subscription = this.client.subscribe(topic, subscribeCallback, headers)
      this.subscriptions[subscription.id] = subscription

      return subscription
    }

    unsubscribe (subscription) {
      subscription.unsubscribe()
    }

    unsubscribeById (id) {
      const subscription = this.subscriptions[id]
      if (subscription) {
        this.unsubscribe(subscription)
      } else {
        this.debug('[StompService]', 'Cannot find a subscription with id', id)
      }
    }

    send (endpoint, headers, data) {
      this.checkClient()

      if (!headers) {
        headers = {}
      }
      const uuid = this.uuidv4()
      headers.stompid = uuid

      this.debug('[StompService]', 'Sending message', endpoint, data, headers)
      this.client.send(endpoint, headers, data)

      return uuid
    }

    subscriberCallbackBuilder (topic, msgHandler, headers = null) {
      const stomp = this
      return function () {
        const subCallback = function (message) {
          const jsonMsg = JSON.parse(message.body)
          msgHandler(jsonMsg)
        }

        if (!headers) {
          headers = {}
        }

        stomp.debug('[StompService]', 'Subscribing to', topic, 'with headers', headers)
        const subscription = stomp.subscribe(topic, subCallback, headers)
        stomp.debug('[StompService]', 'Subscribed to', topic, ' => ', subscription.id)
        return subscription
      }
    }

    /** ########### custom logic follows ################ */

    /** send data as json to the user channel */
    sendToUser (data, headers = null) {
      if (!headers) {
        headers = {}
      }
      return this.send(this.USER_APP_ENDPOINT, headers, JSON.stringify(data))
    }

    sendToPortal (data, headers = null) {
      if (!headers) {
        headers = {}
      }
      return this.send(this.PORTAL_APP_ENDPOINT, headers, JSON.stringify(data))
    }

    sendToAdmin (data, headers = null) {
      if (!headers) {
        headers = {}
      }
      return this.send(this.ADMIN_APP_ENDPOINT, headers, JSON.stringify(data))
    }

    /** user channel subscription */
    subscribeToUserTopic (msgHandler, headers = null) {
      if (!headers) {
        headers = {}
        headers.id = this.USER_DEF_TOPIC
      }
      return this.subscriberCallbackBuilder(this.USER_DEF_TOPIC, msgHandler, headers)
    }

    unsubscribeFromUserTopic () {
      this.unsubscribeById(this.USER_DEF_TOPIC)
    }

    /** portal channel subscription */
    subscribeToPortalTopic (msgHandler, headers = null) {
      if (!headers) {
        headers = {}
        headers.id = this.PORTAL_DEF_TOPIC
      }
      return this.subscriberCallbackBuilder(this.PORTAL_DEF_TOPIC, msgHandler, headers)
    }

    unsubscribeFromPortalTopic () {
      this.unsubscribeById(this.PORTAL_DEF_TOPIC)
    }

    /** portal channel subscription */
    subscribeToAdminTopic (msgHandler, headers = null) {
      if (!headers) {
        headers = {}
        headers.id = this.ADMIN_DEF_TOPIC
      }
      return this.subscriberCallbackBuilder(this.ADMIN_DEF_TOPIC, msgHandler, headers)
    }

    unsubscribeFromAdminTopic () {
      this.unsubscribeById(this.ADMIN_DEF_TOPIC)
    }

    connectUser (user, config) {
      this.debug('[StompService]', 'Connecting user to web socket topics', user)
      const userTopicCallback = this.subscribeToUserTopic(config.userHandler)
      const portalTopicCallback = this.subscribeToPortalTopic(config.portalHandler)
      const adminTopicCallback = this.subscribeToAdminTopic(config.adminHandler)
      let errorCallback = config.errorHandler

      if (!errorCallback) {
        errorCallback = (error) => {
          console.error('[StompService]', 'Connection error')
          console.error(error)
        }
      }

      const connectCallback = () => {
        const uts = userTopicCallback()
        this.debug('[StompService]', 'Subscribed to topic', uts)

        if (user.roles.includes('ROLE_PORTAL') || user.roles.includes('ROLE_ADMIN')) {
          const pts = portalTopicCallback()
          this.debug('[StompService]', 'Subscribed to topic', pts)
        }

        if (user.roles.includes('ROLE_ADMIN')) {
          const ats = adminTopicCallback()
          this.debug('[StompService]', 'Subscribed to topic', ats)
        }
      }

      this.connect(connectCallback, errorCallback)
    }

    /** helper methods */
    uuidv4 () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0; const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    }
}

const stompService = new StompService()

export default stompService

import axios from 'axios'

class DefaultApiService {
    API_URL = process.env.VUE_APP_API_URL;

    VUE_APP_DEFAULT_API_ENDPOINT = process.env.VUE_APP_DEFAULT_API_ENDPOINT

    constructor (apiPath) {
      if (apiPath === undefined) {
        this.apiPath = this.VUE_APP_DEFAULT_API_ENDPOINT
      } else {
        this.apiPath = apiPath
      }
    }

    list (endpoint, params) {
      return axios.get(this.API_URL + this.apiPath + endpoint, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    get (endpoint, id, params) {
      let url = this.API_URL + this.apiPath + endpoint
      if (id) {
        url += '/' + id
      }
      return axios.get(url, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    post (endpoint, data, params) {
      return axios.post(this.API_URL + this.apiPath + endpoint, data, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    put (endpoint, data, params) {
      return axios.put(this.API_URL + this.apiPath + endpoint, data, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    create (endpoint, data, params) {
      return this.post(endpoint, data, params)
    }

    update (endpoint, data, params) {
      return this.put(endpoint, data, params)
    }

    delete (endpoint, id, params) {
      let url = this.API_URL + this.apiPath + endpoint
      if (id) {
        url += '/' + id
      }
      return axios.delete(url, { params: params, withCredentials: true }).then(
        response => {
          return response.data
        },
        error => this.errorHandler(error)
      )
    }

    count (endpoint, params) {
      return axios.get(this.API_URL + this.apiPath + endpoint, { params: params, withCredentials: true }).then(
        response => {
          return response.data.data
        },
        error => this.errorHandler(error)
      )
    }

    errorHandler (error) {
      const customError = {}
      if (error.response) {
        customError.data = error.response.data
        customError.message = error.response.data.exception
        customError.status = error.response.status
        customError.headers = error.response.headers
      } else {
        customError.noResponse = true
        customError.message = error.message
      }
      if (error.request) {
        customError.request = error.request
      }
      customError.config = error.config
      if (typeof error.toJSON === 'function') {
        // safe to use the function
        customError.json = error.toJSON()
      }
      return Promise.reject(customError)
    }
}

export default DefaultApiService

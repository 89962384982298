import DefaultApiService from '@/services/defaultApi.service'
const VUE_APP_ADMIN_API_ENDPOINT = process.env.VUE_APP_ADMIN_API_ENDPOINT
const defaultApiService = new DefaultApiService(VUE_APP_ADMIN_API_ENDPOINT)
const GET_CONTACT = 'getContact'
const SAVE_CONTACT = 'saveContact'
const GET_PERSONAL_DATA = 'getPersonalData'
const SAVE_PERSONAL_DATA = 'savePersonalData'
const GET_AUTH = 'getAuth'
const SAVE_AUTH_MAIL = 'saveAuthMail'
const SAVE_USER_MAIL = 'saveUserMail'
const SEND_ACTIVATION_LINK = 'sendActivationLink'
const SEND_STUDENT_ACTIVATION_LINK = 'sendStudentActivationLink'
const SEND_DOCTORAL_STUDENT_ACTIVATION_LINK = 'sendDoctoralStudentActivationLink'
const SEND_PASSWORD_RESET_LINK = 'sendPasswordResetLink'
const ACTIVATE_USER = 'activateUser'
const TRIGGER_USER = 'triggerUser'
const GET_USER = 'getUser'
const LIST_USER = 'listUsers'
const GET_ORG = 'getOrg'
const SAVE_ORG = 'saveOrg'
const DELETE_ORG = 'deleteOrg'
const LIST_ORG = 'orgs'
const TREE_ORG = 'treeOrg'
const LIST_ALL_ORGS = 'listAllOrgs'

const LIST_AFF = 'listAff'
const GET_AFF = 'getAff'
const SAVE_AFF = 'saveAff'
const TRIGGER_AFF = 'triggerAff'

const LIST_ENT = 'listEnt'
const GET_ENT = 'getEnt'
const SAVE_ENT = 'saveEnt'
const TRIGGER_ENT = 'triggerEnt'

const LIST_FUNC = 'listFunc'
const GET_FUNC = 'getFunc'
const SAVE_FUNC = 'saveFunc'
const CHECK_FUNC = 'checkFunc'
const TRIGGER_FUNC = 'triggerFunc'

const LIST_PROTOCOL = 'listProtocol'

const MAIL_AVAILABLE = 'mailAvailable'

const LIST_AD_GROUPS = 'adGroups'

const LIST_FUNCTION_MAILBOXES = 'functionMailboxes'

const GET_MAILINGLIST = 'getMailingList'
const SAVE_MAILINGLIST = 'saveMailingList'
const DELETE_MAILINGLIST = 'deleteMailingList'
const SYNC_MAILINGLIST = 'syncMailingList'
const LIST_MAILINGLIST = 'mailingLists'

const GET_SHAREDFOLDER = 'getSharedFolder'
const SAVE_SHAREDFOLDER = 'saveSharedFolder'
const DELETE_SHAREDFOLDER = 'deleteSharedFolder'
const LIST_SHAREDFOLDER = 'sharedFolders'
const LIST_PARENTSHAREDFOLDER = 'parentSharedFolders'
const TREE_SHAREDFOLDER = 'treeSharedFolders'
const ENDPOINT_SEARCH_TREE_SHAREDFOLDER = 'searchTreeSharedFolders'
const GET_PARENT_SHARED_FOLDER_ID = 'getParentSharedFolderIds'
const LIST_FUNC_TYPE = 'listFuncType'
const GET_PROCESS_COMMAND = 'getProcessCommand'

const GET_CHIPCARD = 'getChipCard'
const SAVE_CHIPCARD = 'saveChipCard'
const DELETE_CHIPCARD = 'deleteChipCard'
const LIST_CHIPCARDS = 'chipCards'

const LIST_SAP_FUNCTIONS = 'sapFunctions'

const GET_VERSIONED_DATA = 'getVersionedData'

class AdminService {
  list (endpoint, params) {
    return defaultApiService.list(endpoint, params)
  }

  get (endpoint, id, params) {
    return defaultApiService.get(endpoint, id, params)
  }

  create (endpoint, data, params) {
    return defaultApiService.create(endpoint, data, params)
  }

  update (endpoint, data, params) {
    return defaultApiService.update(endpoint, data, params)
  }

  delete (endpoint, id, params) {
    return defaultApiService.delete(endpoint, id, params)
  }

  count (endpoint, params) {
    return defaultApiService.count(endpoint, params)
  }

  post (endpoint, data, params) {
    return defaultApiService.create(endpoint, data, params)
  }

  getPersonalData (id) {
    return defaultApiService.get(GET_PERSONAL_DATA, id)
  }

  savePersonalData (data, params) {
    return defaultApiService.update(SAVE_PERSONAL_DATA, data, params)
  }

  getContact (id) {
    return defaultApiService.get(GET_CONTACT, id)
  }

  saveContact (data, params) {
    return defaultApiService.update(SAVE_CONTACT, data, params)
  }

  getAuth (id) {
    return defaultApiService.get(GET_AUTH, id)
  }

  saveAuthMail (data, params) {
    return defaultApiService.update(SAVE_AUTH_MAIL, data, params)
  }

  saveUserMail (data, params) {
    return defaultApiService.update(SAVE_USER_MAIL, data, params)
  }

  sendActivationLink (id) {
    return defaultApiService.update(SEND_ACTIVATION_LINK, {}, { id: id })
  }

  sendStudentActivationLink (id) {
    return defaultApiService.update(SEND_STUDENT_ACTIVATION_LINK, {}, { id: id })
  }

  sendDoctoralStudentActivationLink (id) {
    return defaultApiService.update(SEND_DOCTORAL_STUDENT_ACTIVATION_LINK, {}, { id: id })
  }

  sendPasswordResetLink (id) {
    return defaultApiService.update(SEND_PASSWORD_RESET_LINK, {}, { id: id })
  }

  activateUser (id) {
    return defaultApiService.update(ACTIVATE_USER, {}, { id: id })
  }

  triggerUser (id) {
    return defaultApiService.update(TRIGGER_USER, {}, { id: id })
  }

  triggerUserWithEntitlements (id) {
    return defaultApiService.update(TRIGGER_USER, {}, { id: id, withEntitlements: true })
  }

  getUser (id) {
    return defaultApiService.get(GET_USER, id)
  }

  listUser (params) {
    return defaultApiService.list(LIST_USER, params)
  }

  // Organisations

  listOrg (params) {
    return defaultApiService.list(LIST_ORG, params)
  }

  getOrg (id) {
    return defaultApiService.get(GET_ORG, id)
  }

  saveOrg (data, params) {
    return defaultApiService.update(SAVE_ORG, data, params)
  }

  deleteOrg (id) {
    return defaultApiService.delete(DELETE_ORG, id)
  }

  treeOrg (params) {
    return defaultApiService.list(TREE_ORG, params)
  }

  listAllOrgs (params) {
    return defaultApiService.list(LIST_ALL_ORGS, params)
  }

  // Affiliations

  listAff (params) {
    return defaultApiService.list(LIST_AFF, params)
  }

  getAff (id) {
    return defaultApiService.get(GET_AFF, id)
  }

  saveAff (data, params) {
    return defaultApiService.update(SAVE_AFF, data, params)
  }

  triggerAff (id) {
    return defaultApiService.update(TRIGGER_AFF, {}, { id: id })
  }

  // Entitlements

  listEnt (params) {
    return defaultApiService.list(LIST_ENT, params)
  }

  getEnt (id) {
    return defaultApiService.get(GET_ENT, id)
  }

  saveEnt (data, params) {
    return defaultApiService.update(SAVE_ENT, data, params)
  }

  triggerEnt (id) {
    return defaultApiService.update(TRIGGER_ENT, {}, { id: id })
  }

  // Functions

  listFunc (params) {
    return defaultApiService.list(LIST_FUNC, params)
  }

  getFunc (id) {
    return defaultApiService.get(GET_FUNC, id)
  }

  saveFunc (data, params) {
    return defaultApiService.update(SAVE_FUNC, data, params)
  }

  checkFunc (data, params) {
    return defaultApiService.update(CHECK_FUNC, data, params)
  }

  triggerFunc (id) {
    return defaultApiService.update(TRIGGER_FUNC, {}, { id: id })
  }

  // protocols

  listProtocol (params) {
    return defaultApiService.list(LIST_PROTOCOL, params)
  }

  mailAvailable (id) {
    return defaultApiService.update(MAIL_AVAILABLE, {}, { id: id })
  }

  // AD groups

  listAdGroups (params) {
    return defaultApiService.list(LIST_AD_GROUPS, params)
  }

  // function mailboxes

  listFunctionMailboxes (params) {
    return defaultApiService.list(LIST_FUNCTION_MAILBOXES, params)
  }

  // MailingLists

  listMailingList (params) {
    return defaultApiService.list(LIST_MAILINGLIST, params)
  }

  getMailingList (id) {
    return defaultApiService.get(GET_MAILINGLIST, id)
  }

  saveMailingList (data, params) {
    return defaultApiService.update(SAVE_MAILINGLIST, data, params)
  }

  deleteMailingList (id) {
    return defaultApiService.delete(DELETE_MAILINGLIST, id)
  }

  syncMailingList (params) {
    return defaultApiService.list(SYNC_MAILINGLIST, params)
  }

  // SharedFolder

  listSharedFolder (params) {
    return defaultApiService.list(LIST_SHAREDFOLDER, params)
  }

  listParentSharedFolder (params) {
    return defaultApiService.list(LIST_PARENTSHAREDFOLDER, params)
  }

  getSharedFolder (id) {
    return defaultApiService.get(GET_SHAREDFOLDER, id)
  }

  saveSharedFolder (data, params) {
    return defaultApiService.update(SAVE_SHAREDFOLDER, data, params)
  }

  deleteSharedFolder (id) {
    return defaultApiService.delete(DELETE_SHAREDFOLDER, id)
  }

  treeSharedFolder (params) {
    return defaultApiService.list(TREE_SHAREDFOLDER, params)
  }

  searchTreeSharedFolders (params) {
    return defaultApiService.list(ENDPOINT_SEARCH_TREE_SHAREDFOLDER, params)
  }

  getParentSharedFolderIds (params) {
    return defaultApiService.list(GET_PARENT_SHARED_FOLDER_ID, params)
  }

  listFuncTypes (params) {
    return defaultApiService.list(LIST_FUNC_TYPE, params)
  }

  // Process Command
  getProcessCommand (id) {
    return defaultApiService.get(GET_PROCESS_COMMAND, id)
  }

  // ChipCards

  listChipCards (params) {
    return defaultApiService.list(LIST_CHIPCARDS, params)
  }

  getChipCard (id) {
    return defaultApiService.get(GET_CHIPCARD, id)
  }

  saveChipCard (data, params) {
    return defaultApiService.update(SAVE_CHIPCARD, data, params)
  }

  deleteChipCard (id) {
    return defaultApiService.delete(DELETE_CHIPCARD, id)
  }

  // SAP functions
  listSapFunctions (params) {
    return defaultApiService.list(LIST_SAP_FUNCTIONS, params)
  }

  getVersionedData (params) {
    return defaultApiService.list(GET_VERSIONED_DATA, params)
  }
}

export default new AdminService()

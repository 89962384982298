import _ from 'lodash'

const LS_KEY = 'prefs'
const EMPTY_STATE = {
  lang: navigator.language || 'de',
  theme: 'auto',
  table: {
    perPage: 10
  }
}
let initialState = JSON.parse(localStorage.getItem(LS_KEY))
if (initialState === null) {
  initialState = {}
}
initialState = _.merge(_.cloneDeep(EMPTY_STATE), initialState)
// ensure supported languages
if (typeof initialState.lang === 'string') {
  initialState.lang = initialState.lang.substring(0, 2).toLowerCase()
}
if (!['de', 'en'].includes(initialState.lang)) {
  initialState.lang = 'en'
}
localStorage.setItem(LS_KEY, JSON.stringify(initialState))

export const prefs = {
  namespaced: true,
  state: initialState,
  mutations: {
    purge (state) {
      localStorage.removeItem(LS_KEY)
      console.log(state)
      // eslint-disable-next-line no-unused-vars
      // state = _.cloneDeep(EMPTY_STATE)
    },
    lang (state, lang) {
      if (state.lang !== lang) {
        state.lang = lang
        localStorage.setItem(LS_KEY, JSON.stringify(state))
      }
    },
    tablePerPage (state, perPage) {
      if (state.table.perPage !== perPage) {
        state.table.perPage = perPage
        localStorage.setItem(LS_KEY, JSON.stringify(state))
      }
    },
    theme (state, theme) {
      if (state.theme !== theme) {
        state.theme = theme
        localStorage.setItem(LS_KEY, JSON.stringify(state))
      }
    }
  },
  actions: {
  }
}

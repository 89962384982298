<template>
  <div role="navigation">
    <b-navbar toggleable="lg" fixed="top" :sticky="true" class="header">
      <b-navbar-brand class="sr-only sr-only-focusable mr-2" href="#main-content">{{ $t('skip.to.main.content.label')}}</b-navbar-brand>
      <b-navbar-brand to="/" :title="$t('app.description')">
        <img src="../assets/icons/logo/idm_blue.svg" height="35px" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item to="/register" v-if="showRegister" active-class="text-link">{{ $t('header.register.label') }}</b-nav-item>
          <b-nav-item to="/user" v-if="showUser" active-class="text-link">{{ $t('header.user.label') }}</b-nav-item>
          <b-nav-item to="/flow" v-if="showFlow" active-class="text-link">{{ $t('header.flow.label') }}</b-nav-item>
          <b-nav-item to="/admin" v-if="showAdminBoard" active-class="text-link">{{ $t('header.admin.label') }}</b-nav-item>
          <b-nav-item to="/conf" v-if="showAdminBoard" active-class="text-link">{{ $t('header.conf.label') }}</b-nav-item>
          <b-nav-item to="/root" v-if="showRootBoard" active-class="text-link">{{ $t('header.root.label') }}</b-nav-item>
          <b-nav-item :href="backendUrl" rel="noreferrer" v-if="showBackendLink" active-class="text-link">{{ $t('header.backend.label') }}</b-nav-item>
          <b-nav-item to="/directory" v-if="showDirectoryBoard" active-class="text-link">{{ $t('header.directory.label') }}</b-nav-item>
          <b-nav-item to="/itapplications" v-if="showItApplicationBoard" active-class="text-link">{{ $t('header.itapplication.label') }}</b-nav-item>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <template v-slot:button-content style="font-size: 125%;">
              <img src="../assets/icons/logo/language.svg" alt="Language switcher" class="svg-lang-switcher"/>
              {{ langName($root.$i18n.locale) }}
            </template>
            <b-dropdown-item v-for="lang in langs" :key="lang" @click="changeLocale(lang)" :lang="lang" class="text-right">{{ langName(lang) }}</b-dropdown-item>
          </b-nav-item-dropdown>

<!--          Session Timer ?-->

          <b-nav-item-dropdown v-if="currentUser" right>
            <template v-slot:button-content>
              <b-icon-person />
            </template>
            <div style="min-width: 250px;">
              <div>
                {{ $t('springSecurity.login.username.label') }}: <em>{{ currentUser.username }}</em>
              </div>
                <div v-if="switched">
                  <b-button variant="warning" class="w-100 my-3" @click="resume"><b-icon-box-arrow-up-right/> {{ $t('header.resume.as.label', { user: switchedUserOriginalUsername }) }}</b-button>
                </div>
                <div v-if="canSwitch" >
                  <b-button class="w-100 my-3" @click="showSwitchModal = true"><b-icon-box-arrow-in-down-left/> {{ $t('header.switch.label') }}</b-button>
                </div>
<!--              </template>-->
              <b-button @click="logout" variant="secondary" class="w-100 mr-0" >
                <b-icon-box-arrow-right/> {{ $t('springSecurity.logout.button') }}
              </b-button>
            </div>
          </b-nav-item-dropdown>
          <b-nav-item v-else @click="showLoginModal = true"  active-class="text-link"><b-icon-box-arrow-in-right/> {{ $t("signin.label") }}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
    <b-modal
      v-model="showLoginModal"
      size="xl"
      hide-footer
    >
      <div class="d-block">
        <b-container fluid>
          <b-row class="m-1">
            <b-col cols="12" :lg="localLoginOnly?12:6">
              <h2 class="text-center mb-3">{{ $t("signin.local.label") }}</h2>
              <b-form @submit="localLogin">
                <b-form-input type="text"     id="username" v-model="username" :disabled="unauthorizedConfirmed" required :placeholder="$t('springSecurity.login.username.label')" class="mb-1"></b-form-input>
                <b-form-input type="password" id="password" v-model="password" required :placeholder="$t('springSecurity.login.password.label')" class="mb-1"></b-form-input>
                <b-button type="submit" variant="success" class="w-100 mb-3">{{ $t("springSecurity.login.title") }}</b-button>
                <div class="text-center"><b-link href="#" @click="gotoRecovery">{{ $t("forgotten.password.label") }}</b-link></div>
              </b-form>
              <template v-if="!localLoginOnly">
              <h2 class="text-center my-3" >{{ $t("signin.sso.label") }}</h2>
              <b-button variant="primary" class="w-100 mb-1" href="saml/login?disco=true&idp=https://www.sso.uni-erlangen.de/simplesaml/saml2/idp/metadata.php">
                {{ $t("signin.sso.fau.label") }}
              </b-button>
              <b-button variant="primary" class="w-100 mb-1" href="saml/login?disco=true&idp=https://www.sso.utn.de/simplesaml/saml2/idp/metadata.php">
                {{ $t("signin.sso.utn.label") }}
              </b-button>
              </template>
            </b-col>
            <b-col cols="12" lg="6" v-if="!localLoginOnly" class="text-center">
              <h2 class="text-center mb-3 text-muted">{{ $t("new.at.tun.label") }}?</h2>
              <b-button @click="gotoRegister" variant="secondary" class="w-100 mr-0" disabled>{{ $t("header.register.label") }}</b-button>
              {{ $t('atm.not.available.label') }}
            </b-col>
          </b-row>
        </b-container>
      </div>
    </b-modal>
    <b-modal
        v-model="showSwitchModal"
        :title="$t('user.switch.title')"
        :ok-title="$t('user.switch.label', {user: switchId?switchId:'...'})"
        :ok-disabled="!switchId"
        :cancel-title="$t('cancel.label')"
        @ok="switchUser"
        @cancel="resetSwitchUser"
        size="lg"
    >
      <SelectUserMin :username.sync="switchIdMUser" show-username/>
    </b-modal>
  </div>
</template>

<script>
import AuthService from '@/services/auth.service'
import { loadLanguageAsync } from '@/i18n'
import { authMixin } from '@/mixins/auth.mixin'
import SelectUserMin from '@/components/admin/SelectUserMin'

export default {
  name: 'TheHeader',
  components: { SelectUserMin },
  mixins: [authMixin],
  data () {
    return {
      backendUrl: process.env.VUE_APP_BACKEND_URL,
      showLoginModal: false,
      showSwitchModal: false,
      switchIdMUser: null,
      langs: ['en', 'de'],
      displayName: null,
      username: null,
      password: null,
      switchedUsername: null,
      loggedIn: false
    }
  },
  computed: {
    langNames () {
      return {
        en: this.$t('lang.en.label'),
        de: this.$t('lang.de.label')
      }
    },
    showRegister () {
      return false
      // return !this.currentUser
    },
    showUser () {
      return !!this.currentUser
    },
    showDirectoryBoard () {
      return !!this.currentUser
    },
    showItApplicationBoard () {
      return this.hasAnyRole(['ROLE_PORTALADMIN', 'ROLE_ADMIN', 'ROLE_ITAPPLICATION'])
    },
    showFlow () {
      return false
    },
    showAdminBoard () {
      return this.hasAnyRole(['ROLE_PORTALADMIN', 'ROLE_ADMIN'])
    },
    showRootBoard () {
      return this.hasAnyRole(['ROLE_ADMIN'])
    },
    showBackendLink () {
      return this.hasAnyRole(['ROLE_ADMIN'])
    },
    switchedAllowed () {
      return this.hasAnyRole(['ROLE_ADMIN'])
    },
    canSwitch () {
      return this.switchedAllowed && !this.switched
    },
    switchId () {
      if (this.switchIdMUser) {
        // return this.switchIdMUser.id
        return this.switchIdMUser
      }
      return null
    },
    unauthorizedConfirmed () {
      return this.$store.state.auth.unauthorized.confirmed
    },
    localLoginOnly () {
      return this.unauthorizedConfirmed
    },
    triggerLogin () {
      return this.$store.state.auth.triggerLogin
    }
  },
  watch: {
    unauthorizedConfirmed (confirmed) {
      if (confirmed && this.currentUser.login === 'local' && this.currentUser.username) {
        this.username = this.currentUser.username
        this.showLoginModal = true
      }
    },
    triggerLogin (newValue) {
      if (newValue) {
        this.showLoginModal = true
        this.$store.commit('auth/triggerLogin', false)
      }
    }
  },
  methods: {
    langName (lang) {
      if (lang in this.langNames) {
        return this.langNames[lang]
      }
      return lang
    },
    changeLocale (lang) {
      this.$store.commit('prefs/lang', lang)
      loadLanguageAsync(lang)
    },
    ping () {
      const requestStartedAt = new Date().getTime()
      this.$store.dispatch('auth/ping').then(
        () => this.makeToast(this.$i18n.t('ping.result.text', { time: new Date().getTime() - requestStartedAt }), this.$i18n.t('result.success.title'), 'success')
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', { status: 'na', message: error.message, id: 'ping' }), this.$i18n.t('result.error.title'), 'danger')
      )
    },
    gotoRegister () {
      this.showLoginModal = false
      this.$router.push('/register')
    },
    gotoRecovery () {
      this.showLoginModal = false
      this.$router.push('/password/recovery')
    },
    resetLocalLogin () {
      this.password = ''
    },
    localLogin (event) {
      event.preventDefault()
      const username = this.username
      const password = this.password
      this.resetLocalLogin()

      AuthService.localLogin(username, password).then(data => {
        if (data.success) {
          this.makeToast(
            this.$i18n.t('login.success.description'),
            this.$i18n.t('result.success.title'),
            'info'
          )
          this.showLoginModal = false
          if (this.$route.name !== 'user' && !this.$store.state.auth.redirectAfterLogin) {
            this.$router.push({ name: 'user' })
          }
        } else {
          this.makeToast(
            this.$i18n.t('login.error.description'),
            this.$i18n.t('result.error.title'),
            'danger'
          )
        }
      }).catch(error => {
        this.makeToast(
          this.$i18n.t('error.text', { status: error.status, message: error.message }),
          this.$i18n.t('result.error.title'),
          'danger')
      }).finally(
        () => this.$store.dispatch('auth/ping')
      )
    },
    logout () {
      AuthService.logout().then(
        () => {
          this.makeToast(
            this.$i18n.t('logout.success.description'),
            this.$i18n.t('result.success.title'),
            'info'
          )
        }).catch(
        error => {
          this.makeToast(
            this.$i18n.t('error.text', { status: error.status, message: error.message }),
            this.$i18n.t('result.error.title'),
            'danger')
        })
    },
    switchUser () {
      AuthService.switch(this.switchId).then(
        response => {
          this.makeToast(this.$i18n.t('switched.text', {
            id: this.switchId,
            code: response.code
          }), this.$i18n.t('result.success.title'), 'success')
          if (this.$route.name !== 'start') {
            this.$router.push({ name: 'start' })
          }
        }).catch(
        error => this.makeToast(this.$i18n.t('error.text', {
          id: this.switchId,
          status: error.status,
          message: error.message
        }), this.$i18n.t('result.error.title'), 'danger')
      ).finally(
        () => this.resetSwitchUser()
      )
    },
    resetSwitchUser () {
      this.switchIdMUser = null
    },
    resume () {
      const savedSwitchedUserOriginalUsername = this.switchedUserOriginalUsername
      AuthService.resume().then(
        response => {
          this.makeToast(this.$i18n.t('resumed.text', {
            id: savedSwitchedUserOriginalUsername,
            code: response.code
          }), this.$i18n.t('result.success.title'), 'success')
        }
      ).catch(
        error => this.makeToast(this.$i18n.t('error.text', {
          id: this.switchId,
          status: error.status,
          message: error.message
        }), this.$i18n.t('result.error.title'), 'danger')
      )
    },
    makeToast (message, title, variant) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant
      })
    }
  }
}
</script>

<style scoped>

</style>


export const authMixin = {
  computed: {
    currentUser () {
      return this.$store.state.auth.user
    },
    authInProgress () {
      return this.$store.state.auth.status.inProgress
    },
    switched () {
      return this.$store.getters['auth/switched']
    },
    switchedUserOriginalUsername () {
      return this.$store.getters['auth/switchedUserOriginalUsername']
    }
  },
  methods: {
    hasAnyRole (roles) {
      if (this.currentUser && this.currentUser.roles) {
        let result = false
        roles.forEach(role => {
          if (this.currentUser.roles.includes(role)) {
            result = true
          }
        })
        return result
      }
      return false
    }
  }
}

import Vue from 'vue'

function trimArray (array, limit) {
  const arrLength = array.length
  if (arrLength > limit) {
    array.splice(0, arrLength - limit)
  }

  return array
}

function handleResp (userMsgs, data) {
  if (data.resp) {
    const respToMsg = userMsgs.find(obj => {
      return obj.stompid === data.resp
    })
    Vue.set(respToMsg, 'resp', data)
  }
}

export const stomp = {
  namespaced: true,
  state: {
    /** audit */
    audit: [],
    showAudits: 30,
    maxAuditSize: 100,
    /** ############### Receive ############## */
    /** user notifications */
    receivedUserMsgs: [],
    receivedUserMsgsMaxSize: 10,
    userMessagesCallbacks: {},
    /** admin notifications */
    receivedAdminMsgs: [],
    receivedAdminMsgsMaxSize: 10,
    adminMessagesCallbacks: {},
    /** portal notifications */
    receivedPortalMsgs: [],
    receivedPortalMsgsMaxSize: 10,
    portalMessagesCallbacks: {},
    /** ############### Send ############## */
    /** user messages */
    userMsgs: [],
    userMsgsMaxSize: 10,
    /** admin messages */
    adminMsgs: [],
    adminMsgsMaxSize: 10,
    /** portal messages */
    portalMsgs: [],
    portalMsgsMaxSize: 10
  },
  getters: {
    auditSize: state => {
      return state.audit.length
    },
    userNotifs: state => {
      return state.receivedUserMsgs.filter(msg => msg.type === 'notif')
    },
    newNotifs: (state, getters) => {
      return getters.userNotifs.filter(notif => notif.proc !== 'done')
    },
    newNotifsSize: (state, getters) => {
      return getters.newNotifs.length
    },
    portalNotifs: state => {
      return this.$store.state.receivedPortalMsgs.filter(msg => msg.type === 'notif')
    },
    adminNotifs: state => {
      return this.$store.state.receivedAdminMsgs.filter(msg => msg.type === 'notif')
    }
  },
  mutations: {
    receiveUserMsg (state, data) {
      handleResp(state.userMsgs, data)
      // data.trg = respToMsg
      state.receivedUserMsgs.push(data)
      state.receivedUserMsgs = trimArray(state.receivedUserMsgs, state.receivedUserMsgsMaxSize)
      if (data.resp) {
        const callback = state.userMessagesCallbacks[data.resp]
        if (callback) {
          callback(data)
          delete state.userMessagesCallbacks[data.resp]
        }
      }
    },
    receiveAdminMsg (state, data) {
      handleResp(state.adminMsgs, data)
      state.receivedAdminMsgs.push(data)
      state.receivedAdminMsgs = trimArray(state.receivedAdminMsgs, state.receivedAdminMsgsMaxSize)
      if (data.resp) {
        const callback = state.adminMessagesCallbacks[data.resp]
        if (callback) {
          callback(data)
          delete state.adminMessagesCallbacks[data.resp]
        }
      }
      /** handle special **/
      /* audit */
      if (data.type === 'audit') {
        state.audit.push(data)
        state.audit = trimArray(state.audit, state.maxAuditSize)
      }
    },
    receivePortalMsg (state, data) {
      handleResp(state.portalMsgs, data)
      state.receivedPortalMsgs.push(data)
      state.receivedPortalMsgs = trimArray(state.receivedPortalMsgs, state.receivedPortalMsgsMaxSize)
      if (data.resp) {
        const callback = state.portalMessagesCallbacks[data.resp]
        if (callback) {
          callback(data)
          delete state.portalMessagesCallbacks[data.resp]
        }
      }
    },
    addUserMsg (state, data) {
      const callback = data.callback
      // delete data.callback
      state.userMsgs.push(data)
      state.userMsgs = trimArray(state.userMsgs, state.userMsgsMaxSize)
      if (callback) {
        state.userMessagesCallbacks[data.stompid] = callback
      }
    },
    addAdminMsg (state, data) {
      const callback = data.callback
      state.adminMsgs.push(data)
      state.adminMsgs = trimArray(state.adminMsgs, state.adminMsgsMaxSize)
      if (callback) {
        state.adminMessagesCallbacks[data.stompid] = callback
      }
    },
    addPortalMsg (state, data) {
      state.portalMsgs.push(data)
      state.portalMsgs = trimArray(state.portalMsgs, state.portalMsgsMaxSize)
    },
    setUserNotifStatus (state, data) {
      const notif = state.receivedUserMsgs.find(msg => msg.stompid === data.stompid)
      if (notif) {
        Vue.set(notif, 'proc', data.status)
      }
    }
  },
  actions: {
  }
}

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import _ from 'lodash'
import LocaleMessageService from '@/services/localeMessage.service'

const dateTimeFormats = {
  en: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  },
  de: {
    short: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    },
    long: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      weekday: 'long',
      hour: 'numeric',
      minute: 'numeric'
    }
  }
}

Vue.use(VueI18n)

Vue.myMessages = {}

const API_URL = process.env.VUE_APP_API_URL
const API_PATH = process.env.VUE_APP_DEFAULT_API_ENDPOINT
const REPORT_MISSINGS = process.env.NODE_ENV === 'development'
const NO_REPORT_MISSINGS = true

const MISSINGS = {}

function getMessageStore (comp) {
  const myStore = Vue.myMessages
  if (!(comp in myStore)) {
    myStore[comp] = {}
  }
  return myStore[comp]
}

function getMessagePromise (comp) {
  if (!('promises' in getMessageStore(comp))) {
    getMessageStore(comp).promises = {}
  }
  return getMessageStore(comp).promises
}

function isLoadingMessages (comp) {
  return getMessageStore(comp).isLoading
}

function setLoadingMessages (comp, isLoading) {
  getMessageStore(comp).isLoading = isLoading
}

export const i18n = new VueI18n({
  dateTimeFormats,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  missing: (locale, key, vm, values) => {
    // handle translation missing
    if (REPORT_MISSINGS && !NO_REPORT_MISSINGS) {
      let comp = 'root'
      if (key.startsWith('comp.')) {
        comp = key.split('.')[1]
      }
      // console.log('grml ' + key)
      if (isLoadingMessages(comp)) {
        return key
      }
      // console.log('root locale: ' + locale + ', key: ' + key + ', vm: ' + vm.$options.name + ', values: ' + JSON.stringify(values))
      // console.log(vm.$options)
      const mkey = locale + ':' + key
      if (!(mkey in MISSINGS)) {
        LocaleMessageService.missing({
          key: key,
          locale: locale,
          values: values
        }).then(() => {
        })
        MISSINGS[mkey] = true
      }
      // else {
      //   // console.log('already ' + key)
      // }
    }
    return key // + '(' + JSON.stringify(values) + ')';
  }
})

export default i18n

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync (lang) {
  if (!_.isEmpty(i18n.getLocaleMessage(lang))) {
    if (i18n.locale === lang) {
      return Promise.resolve(setI18nLanguage(lang))
    }
    return Promise.resolve(setI18nLanguage(lang))
  }
  setLoadingMessages('root', true)
  return axios.get(API_URL + API_PATH + 'lang/' + lang).then(response => {
    const data = response.data
    i18n.setLocaleMessage(lang, data.default)
    return setI18nLanguage(lang)
  }).finally(() => {
    setLoadingMessages('root', false)
  })
}

export function loadComponentLanguageAsync (component) {
  const lang = component.$i18n.locale
  if (!_.isEmpty(component.$i18n.getLocaleMessage(lang))) {
    return Promise.resolve(lang)
  }
  let comp = component.$options.name.toLowerCase()
  if (component.$options.i18nCompName) {
    comp = component.$options.i18nCompName
  }
  setLoadingMessages(comp, true)
  if (getMessagePromise(comp)[lang] !== undefined) {
    return getMessagePromise(comp)[lang].then(data => {
      component.$i18n.setLocaleMessage(lang, data.default)
      return data
    })
  } else {
    getMessagePromise(comp)[lang] = axios.get(API_URL + API_PATH + 'lang/' + lang, { params: { comp: comp } }).then(response => {
      const data = response.data
      component.$i18n.setLocaleMessage(lang, data.default)
      return data
    })
  }
  return getMessagePromise(comp)[lang].finally(() => {
    setLoadingMessages(comp, false)
  })
}

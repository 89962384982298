<template>
  <footer id="layout-footer" class="mt-auto"><div class="cloned-hero-nav"></div>
    <div class="container">
      <div class="footer-row">
        <div class="footer-logo">
          <a href="https://www.utn.de" aria-label="TU Nürnberg">
            <img src="../assets/icons/tun/logo_weiss_einzeilig.svg" class="attachment-medium size-medium" alt="TU Nürnberg" loading="lazy" height="15.511168" width="243.66423">
          </a>
        </div>
        <div class="footer-address">
        </div>
        <div class="footer-meta">
          <nav aria-label="Kontakt, Impressum und Zusatzinformationen">
            <ul id="footer-nav" class="menu" v-if="tecitems">
              <template v-for="item in tecitems">
                <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item" v-if="item.hidden === false" :key="item._id">
                  <router-link :to="item.to" v-if="item.local">{{ $t('footer.' + item.id + '.label') }}</router-link>
                  <a :href="item.url" v-else>{{ $t('footer.' + item.id + '.label') }}</a>
                </li>
              </template>
<!--              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-98109">-->
<!--                <a :href="$t('footer.help.url')">{{ $t('footer.help.label') }}</a>-->
<!--              </li>-->
<!--              <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2262037">-->
<!--                <a :href="$t('footer.contact.url')">{{ $t('footer.contact.label') }}</a>-->
<!--              </li>-->
<!--              <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2238386">-->
<!--                <a :href="$t('footer.imprint.url')">{{ $t('footer.imprint.label') }}</a>-->
<!--              </li>-->
<!--              <li class="menu-item menu-item-type-custom menu-item-object-custom menu-item-2238388">-->
<!--                <a :href="$t('footer.privacy.url')">{{ $t('footer.privacy.label') }}</a>-->
<!--              </li>-->
<!--              <li class="accessability">-->
<!--                <a :href="$t('footer.accessibility.url')">{{ $t('footer.accessibility.label') }}</a>-->
<!--              </li>-->
<!--              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1262108">-->
<!--                <a :href="$t('footer.tou.url')">{{ $t('footer.tou.label') }}</a>-->
<!--              </li>-->
<!--              <li class="menu-item menu-item-type-post_type menu-item-object-page menu-item-1262108">-->
<!--                <a :href="$t('footer.directives.url')">{{ $t('footer.directives.label') }}</a>-->
<!--              </li>-->
            </ul>
          </nav>
          <nav class="svg-socialmedia round hoverbg" aria-label="RRZE Links">
            <div>
              <ul>
                <li class="fau-rrze">
                  <a data-wpel-link="internal" itemprop="sameAs" href="https://rrze.fau.de" aria-label="RRZE">
                    <img src="../assets/icons/logo/rrze.svg" height="35px" alt="RRZE" />
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </div>
    <div>
    </div>
  </footer>
</template>

<script>
import DefaultApiService from '@/services/defaultApi.service'
const defaultApiService = new DefaultApiService()

export default {
  name: 'TheFooter',
  data () {
    return {
      tecitems: []
    }
  },
  methods: {
    loadFooterConfig () {
      defaultApiService.list('listFooter', {}).then((response) => {
        this.tecitems = response
      })
    }
  },
  beforeMount () {
    this.loadFooterConfig()
  }
}
</script>

<style scoped>
</style>

export const editor = {
  namespaced: true,
  state: {
    activeTab: null,
    tabs: []
  },
  mutations: {
    save (state, editor) {
      state.tabs = editor.tabs
      state.activeTab = editor.activeTab
      console.log('editor stuff saved')
    }
  },
  actions: {
  }
}

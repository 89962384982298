import Vue from 'vue'
import Vuex from 'vuex'

import { editor } from '@/store/editor.module'
import { prefs } from '@/store/prefs.module'
import { auth } from '@/store/auth.module'
import { stomp } from '@/store/stomp.module'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || '0',
    pendingChanges: []
  },
  getters: {
    appVersion: (state) => {
      return state.packageVersion
    }
  },
  mutations: {
    addPendingChange (state, pendingChange) {
      const pendingChangeIdx = state.pendingChanges.indexOf(pendingChange)
      if (pendingChangeIdx < 0) {
        state.pendingChanges.push(pendingChange)
      }
    },
    removePendingChange (state, pendingChange) {
      const pendingChangeIdx = state.pendingChanges.indexOf(pendingChange)
      if (pendingChangeIdx >= 0) {
        state.pendingChanges.splice(pendingChangeIdx, 1)
      }
    }
  },
  actions: {
  },
  modules: {
    editor,
    prefs,
    auth,
    stomp
  }
})

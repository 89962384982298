import axios from 'axios'
import router from '@/router'
import store from '@/store'
import qs from 'qs'

const API_URL = process.env.VUE_APP_API_URL
const LOCAL_LOGIN_URL = process.env.VUE_APP_LOGIN_URL
const LOGOUT_URL = process.env.VUE_APP_LOGOUT_URL
const SLO_URL = process.env.VUE_APP_SLO_URL
const LOGIN_SWITCH_URL = process.env.VUE_APP_LOGIN_SWITCH_URL
const LOGOUT_SWITCH_URL = process.env.VUE_APP_LOGOUT_SWITCH_URL
const DEFAULT_API_ENDPOINT = process.env.VUE_APP_DEFAULT_API_ENDPOINT

const receiveMessage = event => {
  // do we trust? might be different from what we originally opened
  const arr = window.location.href.split('/')
  const myOrigin = arr[0] + '//' + arr[2]
  if (event.origin !== myOrigin) {
    return
  }
  // console.log("Got event from " + event.origin);
  const { data } = event
  // console.log("data" + data);
  if (data === 'portalpopup') {
    window.removeEventListener('message', receiveMessage)
    store.dispatch('auth/ping')
  }
}

let windowObjectReference = null
let previousUrl = null

function popupCenter ({ url, windowName, w, h }) {
  const y = window.top.outerHeight / 2 + window.top.screenY - (h / 2)
  const x = window.top.outerWidth / 2 + window.top.screenX - (w / 2)
  return window.open(url, windowName, `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${w}, height=${h}, top=${y}, left=${x}`)
}

const openInWindow = (url, name) => {
  window.removeEventListener('message', receiveMessage)
  // const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100'
  if (windowObjectReference === null || windowObjectReference.closed) {
    // windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference = popupCenter({ url: url, title: name, w: 600, h: 700 })
  } else if (previousUrl !== url) {
    // windowObjectReference = window.open(url, name, strWindowFeatures)
    windowObjectReference = popupCenter({ url: url, title: name, w: 600, h: 700 })
    windowObjectReference.focus()
  } else {
    windowObjectReference.focus()
  }
  window.addEventListener('message', receiveMessage, false)
  previousUrl = url
}

class AuthService {
  switch (username) {
    const credentials = {
      username: username
    }
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    }

    return axios.post(LOGIN_SWITCH_URL, qs.stringify(credentials), config).then(
      response => {
        return response.data
      }).finally(
      () => store.dispatch('auth/ping')
    )
  }

  resume () {
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true
    }

    return axios.post(LOGOUT_SWITCH_URL, null, config).then(
      response => {
        return response.data
      }
    ).finally(
      () => store.dispatch('auth/ping')
    )
  }

  login () {
    // this.localLogin()
  }

  // ext login with redirect
  // loginRedirect () {
  //   window.location = LOGIN_URL
  // }
  //
  // ext login same window
  // login () {
  //   store.commit('auth/inProgress', true)
  //   openInWindow(LOGIN_URL, 'dsmsauth')
  //   // window.location = LOGIN_URL;
  // }

  loginRedirectWindow (loginUrl) {
    store.commit('auth/inProgress', true)
    openInWindow(loginUrl, 'portalauth')
  }

  loginRedirect (loginUrl) {
    window.location = loginUrl
  }

  localLogin (username, password) {
    store.commit('auth/inProgress', true)

    const credentials = {
      username: username,
      password: password
    }
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      withCredentials: true
    }

    return axios.post(LOCAL_LOGIN_URL, qs.stringify(credentials), config).then(
      response => {
        return response.data
      })
    // moved tot TheHeader
    //   .finally(
    //   () => store.dispatch('auth/ping')
    // )
  }

  logout () {
    // return this.sloWindow()
    return this.slo()
    // return this.localLogout()
  }

  slo () {
    window.location = SLO_URL
  }

  sloWindow () {
    if (router.name !== 'start') {
      router.push({ name: 'start' })
    }
    store.commit('auth/inProgress', true)
    openInWindow(SLO_URL, 'portalauth')
  }

  localLogout () {
    if (router.name !== 'start') {
      router.push({ name: 'start' })
    }
    store.commit('auth/inProgress', true)

    // window.open(LOGOUT_URL, '_self')
    const config = {
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      },
      withCredentials: true
    }

    return axios.post(LOGOUT_URL, null, config).then(
      response => {
        return response.data
      }).finally(
      () => store.dispatch('auth/ping')
    )
  }

  ping () {
    return axios.get(API_URL + DEFAULT_API_ENDPOINT + 'ping', { withCredentials: true }).then(
      response => {
        return response.data
      }
    )
  }

  replay (config) {
    return axios.request(config)
  }
}

export default new AuthService()
